import { ComponentType } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import AuthError from '../auth/AuthError';
import AuthInProgress from '../auth/AuthInProgress';

export interface MsalProps {
    request?: {
        scopes?: string[];
    }
}

function WithMsalProtection<T extends object>(
    WrappedComponent: ComponentType<T>,
    { request }: MsalProps
): ComponentType<T> {
    return function ProtectedComponent(props: T) {
        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={request}
                errorComponent={AuthError}
                loadingComponent={AuthInProgress}
            >
                <WrappedComponent {...props} />
            </MsalAuthenticationTemplate>
        );
    };
}

export default WithMsalProtection;
