import { Configuration } from "@azure/msal-browser"

export const configuration: Configuration = {
    auth: {
      clientId: 'c87b67f5-93b6-4e99-94eb-34258063de40',
      authority: 'https://login.b2c.guru/82ae81ee-fe01-420c-bcd0-d5ec9430f2c3/B2C_1A_SIGNUP_SIGNIN',
      redirectUri: 'https://admin.b2c.guru',
      knownAuthorities: ['login.b2c.guru']
    },
  }

export const readRequest = {
  scopes: [
    'https://mallowdemo.onmicrosoft.com/c87b67f5-93b6-4e99-94eb-34258063de40/User.Read'
  ],
}

export const deleteRequest = {
  scopes: [
    'https://mallowdemo.onmicrosoft.com/c87b67f5-93b6-4e99-94eb-34258063de40/User.Delete'
  ],
}

export const loginRequest = {
  // Regular openid scope claims should be included in the idToken
  scopes: ['openid', 'offline_access'],
  // We "pre-consent" to the read and delete scopes, so we don't need to be redirected to the consent page
  // when we actually need these permissions, since these are admin consented scopes anyhow.
  extraScopesToConsent: [ ...readRequest.scopes, ...deleteRequest.scopes ],
}

