function AuthError() {
  return (
    <div>
        <p>
          An error occurred during authentication.
        </p>
    </div>
  );
}

export default AuthError;
