import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Admin from './Admin';
import Home from './Home';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { loginRequest } from './auth';
import { InteractionStatus } from '@azure/msal-browser';
import { AppBar, Button, Container, IconButton, Toolbar, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import WithMsalProtection from './auth/Protected';

function App() {
  const { instance, inProgress } = useMsal();

  const logout = () => {
    instance.logoutRedirect();
  };

  const login = () => {
    instance.loginRedirect(loginRequest);
  };

  const isMsalIdle = inProgress !== InteractionStatus.None;

  const AdminRoute = WithMsalProtection(Admin, { request: loginRequest })

  return (
    <>
      <BrowserRouter>
        <AppBar position="static">
          <Toolbar>
            <Link to="/">
              <IconButton
                size="large"
                edge="start"
                aria-label="home"
                sx={{ mr: 2, color: 'white' }}
              >
                <HomeIcon />
              </IconButton>
            </Link>

            <Button color="inherit" component={Link} to="/">
              Home
            </Button>

            <Button color="inherit" component={Link} to="/admin">
              Manage
            </Button>

            <Typography variant="h6" sx={{ flexGrow: 1 }} />
          
            <AuthenticatedTemplate>
              <Button
                onClick={logout}
                color="inherit"
                disabled={isMsalIdle}
              >
                Log out
              </Button>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Button
                onClick={login}
                disabled={isMsalIdle}
                color="inherit"
              >
                Log in
              </Button>
            </UnauthenticatedTemplate>
          </Toolbar>
        </AppBar>
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<AdminRoute />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </>
  );
}

export default App;
