function AuthInProgress() {
  return (
    <div>
        <p>
          Authentication in progress...
        </p>
    </div>
  );
}

export default AuthInProgress;
