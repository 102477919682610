import { useMsal } from "@azure/msal-react";
import { deleteRequest, readRequest } from "./auth";
import { Box, IconButton, LinearProgress, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PopupRequest, SilentRequest } from "@azure/msal-browser";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const serviceEnpoint = "https://admin.b2c.guru/api";
const usersEndpoint = () => `${serviceEnpoint}/users`;
const userEndpoint = (id: string) => `${serviceEnpoint}/users/${id}`;

function Admin() {
  const { instance, accounts, inProgress } = useMsal();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<{[key: string]: string | null} | null>(null);
  const [mutationCount, setMutationCount] = useState(0);

  const [userLoadingState, setUserLoadingState] = useState<'none' | 'loading' | 'loaded' | 'error'>('none');
  const [usersLoadingState, setUsersLoadingState] = useState<'none' | 'loading' | 'loaded' | 'error'>('none');

  const readTokenRequest: SilentRequest | PopupRequest = {
    ...readRequest,
    account: accounts[0] ?? {}
  };

  const deleteTokenRequest: SilentRequest | PopupRequest = {
    ...deleteRequest,
    account: accounts[0] ?? {}
  };

  const authenticationReady = inProgress === "none";

  const getAccessToken = async (requestParams: SilentRequest | PopupRequest) => {
    // Tries to fetch token on the background (cache)
    const silentResponse = await instance.acquireTokenSilent(requestParams);
    if (silentResponse.accessToken) {
      return silentResponse.accessToken;
    }

    // If the silent request fails, fallback to an interactive call. This happens usually
    // when the sso session has not established consent for the requested scopes, or the
    // tokens need to be refreshed
    const interactiveResponse = await instance.acquireTokenPopup(requestParams);
    return interactiveResponse.accessToken;
  };

  const fetchUser = async (id: string) => {
    try {
      setUserLoadingState('loading');
      const userResponse = await fetch(userEndpoint(id), {
        headers: {
          'authorization': `Bearer ${await getAccessToken(readTokenRequest)}`
        }
      });
      const userReponseContent = await userResponse.json();
      setUser(userReponseContent);
      setUserLoadingState('loaded');
    } catch (e) {
      setUserLoadingState('error');
    }
  };

  const deleteUser = async (id: string) => {
    try {
      setUserLoadingState('loading');
      await fetch(userEndpoint(id), {
        method: 'DELETE',
        headers: {
          'authorization': `Bearer ${await getAccessToken(deleteTokenRequest)}`
        }
      });
      setUser(null);
      setMutationCount(mutationCount + 1);
      setUserLoadingState('loaded');
    } catch (e) {
      setUserLoadingState('error');
    }
  };

  const fetchUsers = async () => {
    try {
      setUsersLoadingState('loading');
      const usersResponse = await fetch(usersEndpoint(), {
        headers: {
          'authorization': `Bearer ${await getAccessToken(readTokenRequest)}`
        }
      });
      const usersReponseContent = await usersResponse.json();
      setUsers(usersReponseContent);
      setUsersLoadingState('loaded');
    } catch (e) {
      setUsersLoadingState('error');
    }
  };
  
  useEffect(() => {
    if (authenticationReady) fetchUsers();
  }, [authenticationReady, mutationCount]);

  const userSxProps: SxProps<Theme> = {
    display: 'flex',
    gap: 3,
    padding: 1,
  };

  const activeUserSxProps: SxProps<Theme> = {
    ...userSxProps,
    backgroundColor: '#E8F4FD',
  };

  return (<>
    {usersLoadingState === 'loading' && (
      <LinearProgress sx={{ width: '100%', marginTop: 3 }}/>
    )}
    {usersLoadingState !== 'loading' && (
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          marginTop: 3
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 3, flexBasis: 1, flexGrow: 1 }}
        >
          {users.map((u: any) =>
            <Box sx={user?.id && user.id === u.id ? activeUserSxProps : userSxProps} key={u.id}>
              <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flexGrow: 1 }}>
                <Typography variant="button">{u.displayName}</Typography>
                <Typography variant="caption" sx={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{u.userPrincipalName}</Typography> 
              </Box>
              <IconButton
                color="inherit"
                sx={{ display: 'block', whiteSpace: 'normal' }}
                onClick={() => fetchUser(u.id)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flexBasis: 1,
            flexGrow: 1,
          }}
        >
          {userLoadingState === 'loading' && (
            <LinearProgress sx={{ width: '100%' }}/>
          )}
          {user?.id && userLoadingState !== 'loading' && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4">{user?.displayName}</Typography>
                  <IconButton
                    size="small"
                    aria-label="delete user"
                    sx={{ mx: 3, color: 'red' }}
                    disabled={user?.userPrincipalName?.includes('#EXT#@')}
                    onClick={() => deleteUser(user.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
              </Box>
              <Typography variant="caption">{user?.userPrincipalName}</Typography>
              <Box sx={{ marginTop: 3 }}>
                <Typography component="pre">
                  {JSON.stringify(user, null, 2)}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    )}
    </>
  );
}

export default Admin;
